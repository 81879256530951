import React from 'react';
import { useTranslation } from 'react-i18next';

import DataBuilder from '../components/DataBuilder';
import Layout from '../components/Layout';

const FormPage = ({
  location: { state } = {},
}) => {
  const { t } = useTranslation();

  return (
    <Layout
      footer={false}
      title={t('Aide à la saisie de données')}
      fluid={false}
    >
      <DataBuilder from={state} />
    </Layout>
  );
};

export default FormPage;
