import React from 'react';

const Coordinates = props => {
  const { registry: { fields: { ObjectField } } } = props;

  return (
    <>
      <ObjectField {...props} />
      {/* <>Map !</> */}
    </>
  );
};

export default Coordinates;
