/* eslint-disable import/prefer-default-export */
import { graphql, useStaticQuery } from 'gatsby';

export const useSchemas = () => {
  const { wrapper: { nodes } } = useStaticQuery(graphql`
    {
      wrapper: allFile(
        filter: { relativePath: { regex: "/^schema(.*)[^\\.ui]\\.json$/" } }
      ) {
        nodes {
          name
          internal { content }
        }
      }
    }
  `);

  return nodes.reduce(
    (acc, { name, internal: { content } }) => {
      try {
        const schema = JSON.parse(content);
        return { ...acc, [name]: schema };
      } catch (e) {
        return acc;
      }
    },
    {},
  );
};

export default useSchemas;
