/* eslint-disable no-bitwise */
import React from 'react';

import Editor from '@monaco-editor/react';
import { useTranslation } from 'react-i18next';

import useSchemas from '../hooks/useSchemas';

const editorDefaultOptions = {
  colorDecorators: true,
  cursorBlinking: 'expand',
  emptySelectionClipboard: true,
  mouseWheelZoom: true,
  multiCursorModifier: 'ctrlCmd',
  renderLineHighlight: 'line',
  scrollBeyondLastLine: false,
  showFoldingControls: 'always',
  wordWrap: 'on',
};

const JsonEditor = ({
  formData = '',
  schema,
  path,
  onSave,
  ...props
}) => {
  const schemas = useSchemas();
  const [ww, setWw] = React.useState(true);
  const { t } = useTranslation();

  const handleSave = React.useCallback(() => {
    if (typeof onSave === 'function') {
      onSave();
    }
  }, [onSave]);

  const handleEditorDidMount = (editor, monaco) => {
    const schemaBlobUri = URL.createObjectURL(
      new Blob([JSON.stringify(schemas[schema])], { type: 'application/json' }),
    );

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      enableSchemaRequest: true,
      validate: true,
      schemas: [
        {
          uri: schemaBlobUri,
          fileMatch: [path],
          schema: schemas[schema],
        },
      ],
    });

    editor.addAction({
      id: 'editor.wordwrap.toggle',
      label: t('Activer/désactiver le retour à la ligne'),
      keybindings: [monaco.KeyMod.Alt | monaco.KeyCode.KEY_Z],
      run: () => setWw(a => !a),
    });

    editor.addAction({
      id: 'download',
      label: t('Télécharger'),
      contextMenuGroupId: 'manageFile',
      keybindings: [
        monaco.KeyMod.Alt | monaco.KeyCode.KEY_S,
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S,
      ],
      run: handleSave,
    });
  };

  const editorOptions = React.useMemo(
    () => ({
      ...editorDefaultOptions,
      wordWrap: ww ? 'on' : 'off',
    }),
    [ww],
  );

  return (
    <Editor
      height="300px"
      defaultLanguage="json"
      defaultValue={JSON.stringify(formData, null, 2)}
      value={JSON.stringify(formData, null, 2)}
      onMount={handleEditorDidMount}
      options={editorOptions}
      path={path}
      {...props}
    />
  );
};

export default React.memo(JsonEditor);
