import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import {
  Box,
  ButtonGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-material-ui-components';

import { withTheme } from '@rjsf/core';
import { Theme as MaterialUITheme } from '@rjsf/material-ui';
import StringField from '@rjsf/core/lib/components/fields/StringField';

import useSchemas from '../hooks/useSchemas';
import useUiSchemas from '../hooks/useUiSchemas';
import Coordinates from './Coordinates';
import Autocomplete from './Autocomplete';
import { usePlatformValues } from '../hooks/usePlatforms';

import CustomArrayFieldTemplate from './rjsf-mui/ArrayFieldTemplate';
import CustomObjectFieldTemplate from './rjsf-mui/ObjectFieldTemplate';
import JsonEditor from './JsonEditor';
import { isLive } from '../helpers';

const enhanceUiSchema = (uiSchema, values) => {
  if (!uiSchema) {
    return {};
  }
  Object.keys(uiSchema).forEach(key => {
    if (uiSchema[key]['ui:widget'] === 'autocomplete' && values[key]) {
      // eslint-disable-next-line no-param-reassign
      uiSchema[key]['ui:options'] = {
        ...(uiSchema[key]?.['ui:options'] || {}),
        props: {
          ...(uiSchema[key]?.['ui:options']?.props || {}),
          options: values[key],
        },
      };
    }
  });

  return uiSchema;
};

const Form = isLive ? withTheme(MaterialUITheme) : React.Fragment;

const useStyles = makeStyles(theme => ({
  layout: {
    height: `calc(100% - ${theme.spacing(1)}px)`,
    display: 'grid',
    gridTemplateAreas: `
      "pageHead pageHead"
      "formHead editorHead"
      "form editor"`,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto 1fr',
  },

  pageHead: {
    paddingTop: theme.spacing(2),
    gridArea: 'pageHead',
  },

  formHead: ({ customSchema }) => ({
    gridArea: 'formHead',
    display: customSchema ? 'initial' : 'none',
  }),

  formWrapper: {
    gridArea: 'form',
    overflow: 'auto',
    paddingRight: theme.spacing(1),
  },

  editorHead: {
    gridArea: 'editorHead',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },

  filename: {},

  editorActionsTip: {
    fontStyle: 'italic',
    opacity: 0.4,
    lineHeight: 1.2,
    fontSize: '0.9em',
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      opacity: 0.8,
    },
  },

  editorWrapper: {
    gridArea: 'editor',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    '& > section': {
      flexGrow: 1,
    },
  },
}));

const customFields = {
  coordinates: Coordinates,
  StringField: props => (
    // eslint-disable-next-line react/destructuring-assignment
    props?.schema?.const
      ? null
      : <StringField {...props} />
  ),
};

const customWidgets = {
  autocomplete: Autocomplete,
};

const customTemplates = {
  ArrayFieldTemplate: CustomArrayFieldTemplate,
  ObjectFieldTemplate: CustomObjectFieldTemplate,
};

const defaultJsonName = 'default.json';

const DataBuilder = ({ from }) => {
  const { t } = useTranslation();
  const initialFilename = from?.filename?.replace(/^.*[\\/]/, '') || defaultJsonName;

  const schemas = useSchemas();
  const uiSchemas = useUiSchemas();
  const classes = useStyles();
  const platformValues = usePlatformValues();

  const [formData, setFormData] = React.useState(from?.data || {});
  const [schema, setSchema] = React.useState(from?.schema || 'platform');
  const [filename, setFilename] = React.useState(initialFilename);

  const isFilenameValid = filename.substr(-5) === '.json'
    && filename.length > 5;

  const handleSchemaChange = (event, newValue) => {
    if (schema === newValue) {
      return;
    }

    setSchema(newValue);
    setFormData({});
  };

  const handleJsonChange = newValue => {
    try {
      setFormData(JSON.parse(newValue));
    } catch (err) {
      // console.error(err);
    }
  };

  const handleDownload = () => {
    if (!isFilenameValid) {
      return;
    }

    const json = JSON.stringify(formData, null, 2);
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(json)}`);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleFormChange = React.useCallback(event => setFormData(event.formData), []);

  return (
    <Box className={classes.layout}>
      <Box className={classes.pageHead}>
        <Typography variant="h2" component="h1">
          {t('Aide à la saisie de données')}
        </Typography>
      </Box>

      <Box className={classes.formHead}>
        <Tabs
          value={schema}
          onChange={handleSchemaChange}
          centered
          indicatorColor="primary"
          textColor="primary"
        >
          {Object.keys(schemas).map(schemaName => (
            <Tab key={schemaName} label={schemaName} value={schemaName} />
          ))}
        </Tabs>
      </Box>

      <Box className={classes.formWrapper}>
        <Form
          schema={schemas[schema]}
          uiSchema={enhanceUiSchema(uiSchemas[schema], platformValues)}
          formData={formData}
          onChange={handleFormChange}
          tagName="div"
          fields={customFields}
          widgets={customWidgets}
          {...customTemplates}
        >
          <></>
        </Form>
      </Box>

      <Box className={classes.editorHead}>
        <TextField
          label={t('Nom du fichier')}
          variant="outlined"
          size="small"
          className={classes.filename}
          value={filename}
          error={!isFilenameValid}
          onChange={({ target }) => setFilename(target.value)}
        />

        <ButtonGroup>
          <Button
            variant="contained"
            onClick={handleDownload}
            startIcon={<GetApp />}
            color="primary"
            disabled={!isFilenameValid}
          >
            {t('Télécharger')}
          </Button>
        </ButtonGroup>

        <Box className={classes.editorActionsTip}>
          <Trans>
            Utilisez <strong>F1</strong> pour ouvrir<br />
            le panneau de commandes
          </Trans>
        </Box>
      </Box>

      <Box className={classes.editorWrapper}>
        <JsonEditor
          schema={schema}
          onChange={handleJsonChange}
          formData={formData}
          path={isFilenameValid ? filename : defaultJsonName}
          onSave={handleDownload}
        />
      </Box>
    </Box>
  );
};

export default DataBuilder;
