import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';

const Autocomplete = ({
  options: { props = {} } = {},
  onChange,
  value = '',

  label,
  autofocus,
  placeholder,
  disabled,
  readonly,
  required,
  id,
  // ...rest
}) => (
  <MUIAutocomplete
    {...props}
    freeSolo
    autoHighlight
    id={id}
    value={value}
    inputValue={value}
    onChange={(event, newInputValue) => onChange(newInputValue)}
    onInputChange={(event, newInputValue) => onChange(newInputValue)}
    disableClearable
    renderInput={params => (
      <TextField
        {...params}
        fullWidth
        value={value}
        label={label}
        autoFocus={autofocus}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        required={required}
      />
    )}
  />
);

export default Autocomplete;
