import React from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MuiIconButton from '@material-ui/core/IconButton';

import { UnfoldLess, UnfoldMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { utils } from '@rjsf/core';

import AddButton from './AddButton';
import IconButton from './IconButton';
import useServiceStyles from '../../hooks/useServiceStyles';

const {
  isMultiSelect,
  getDefaultRegistry,
} = utils;

const ArrayFieldTemplate = props => {
  const { schema, registry = getDefaultRegistry() } = props;

  if (isMultiSelect(schema, registry.rootSchema)) {
    return <DefaultFixedArrayFieldTemplate {...props} />;
  }
  return <DefaultNormalArrayFieldTemplate {...props} />;
};

const ArrayFieldTitle = ({
  TitleField,
  idSchema,
  title,
  required,
}) => {
  if (!title) {
    return null;
  }

  const id = `${idSchema.$id}__title`;
  return <TitleField id={id} title={title} required={required} />;
};

const ArrayFieldDescription = ({
  DescriptionField,
  idSchema,
  description,
}) => {
  if (!description) {
    return null;
  }

  const id = `${idSchema.$id}__description`;
  return <DescriptionField id={id} description={description} />;
};

const useDefaultArrayItemStyles = makeStyles(theme => ({
  foldButton: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.secondary.main}`,
    zIndex: 2,
  },

  itemBox: {
    position: 'relative',
  },

  itemPaper: ({ folded }) => ({
    height: folded ? 200 : 'auto',
    overflow: 'auto',
    border: '1px solid rgba(0, 0, 0, .1)',
    marginTop: theme.spacing(2),
  }),
}));

// Used in the two templates
const DefaultArrayItem = ({
  children,
  disabled,
  hasMoveDown,
  hasMoveUp,
  hasRemove,
  hasToolbar,
  index,
  onDropIndexClick,
  onReorderClick,
  readonly,
  formData,
}) => {
  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
    minWidth: 0,
  };

  const [folded, setFolded] = React.useState(false);
  const classes = useDefaultArrayItemStyles({ folded });
  const { classes: srvClasses } = useServiceStyles();

  return (
    <Grid container alignItems="center">
      <Grid item xs style={{ overflow: 'auto' }}>
        <Box mb={2} className={classes.itemBox}>
          <MuiIconButton
            color="secondary"
            size="small"
            onClick={() => setFolded(prev => !prev)}
            className={classes.foldButton}
          >
            {!folded && <UnfoldLess fontSize="inherit" />}
            {folded && <UnfoldMore fontSize="inherit" />}
          </MuiIconButton>

          <Paper
            elevation={2}
            className={clsx(
              classes.itemPaper,
              srvClasses[formData?.['Service type']],
            )}
          >
            <Box p={2}>{children}</Box>
          </Paper>
        </Box>
      </Grid>

      {hasToolbar && (
        <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              icon="arrow-up"
              className="array-item-move-up"
              tabIndex={-1}
              style={btnStyle}
              iconProps={{ fontSize: 'small' }}
              disabled={disabled || readonly || !hasMoveUp}
              onClick={onReorderClick(index, index - 1)}
            />
          )}

          {hasRemove && (
            <IconButton
              icon="remove"
              tabIndex={-1}
              style={btnStyle}
              iconProps={{ fontSize: 'small' }}
              disabled={disabled || readonly}
              onClick={onDropIndexClick(index)}
            />
          )}

          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              icon="arrow-down"
              tabIndex={-1}
              style={btnStyle}
              iconProps={{ fontSize: 'small' }}
              disabled={disabled || readonly || !hasMoveDown}
              onClick={onReorderClick(index, index + 1)}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

const DefaultFixedArrayFieldTemplate = ({
  canAdd,
  className,
  disabled,
  idSchema,
  items,
  onAddClick,
  readonly,
  required,
  schema,
  title,
  TitleField,
  uiSchema,
}) => (
  <fieldset className={className}>
    <ArrayFieldTitle
      key={`array-field-title-${idSchema.$id}`}
      TitleField={TitleField}
      idSchema={idSchema}
      title={uiSchema['ui:title'] || title}
      required={required}
    />

    {(uiSchema['ui:description'] || schema.description) && (
    <div
      className="field-description"
      key={`field-description-${idSchema.$id}`}
    >
      {uiSchema['ui:description'] || schema.description}
    </div>
    )}

    <div
      className="row array-item-list"
      key={`array-item-list-${idSchema.$id}`}
    >
      {items && items.map(p => <DefaultArrayItem {...p} />)}
    </div>

    {canAdd && (
    <AddButton
      className="array-item-add"
      onClick={onAddClick}
      disabled={disabled || readonly}
    />
    )}
  </fieldset>
);

const DefaultNormalArrayFieldTemplate = ({
  canAdd,
  DescriptionField,
  disabled,
  idSchema,
  items,
  onAddClick,
  readonly,
  required,
  schema,
  title,
  TitleField,
  uiSchema,
  formData,
}) => (
  <Paper elevation={2}>
    <Box p={2}>
      <ArrayFieldTitle
        key={`array-field-title-${idSchema.$id}`}
        TitleField={TitleField}
        idSchema={idSchema}
        title={uiSchema['ui:title'] || title}
        required={required}
      />

      {(uiSchema['ui:description'] || schema.description) && (
      <ArrayFieldDescription
        key={`array-field-description-${idSchema.$id}`}
        DescriptionField={DescriptionField}
        idSchema={idSchema}
        description={
              uiSchema['ui:description'] || schema.description
            }
      />
      )}

      <Grid container key={`array-item-list-${idSchema.$id}`}>
        {items && items.map((p, index) => (
          <DefaultArrayItem formData={formData[index]} {...p} />
        ))}

        {canAdd && (
        <Grid container justify="flex-end">
          <Grid item>
            <Box mt={2}>
              <AddButton
                className="array-item-add"
                onClick={onAddClick}
                disabled={disabled || readonly}
              />
            </Box>
          </Grid>
        </Grid>
        )}
      </Grid>
    </Box>
  </Paper>
);

export default ArrayFieldTemplate;
